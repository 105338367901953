import { useQuery } from 'react-query'

import {
    Container,
    ListContainer,
    Wrapper,
    Image,
    DescriptionWrapper,
    Description,
    Title,
    Date,
} from './styles'

const Feed = () => {
    const images = [
        '1.jpg',
        '2.jpg',
        '4.jpg',
        '5.jpg',
        '6.jpg',
        '7.jpg',
        '9.jpg'
    ]
    return (
        <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            <ListContainer className="no-scroll">
            { 
                images.map(image => {
                    return (
                        <Wrapper key={image}>
                            <Image img={`/feed/${image}`} />
                        </Wrapper>
                    )
                }) 
            }
            <div style={{minHeight:"10px"}} />
            </ListContainer>
        </Container>
    )
}

export default Feed